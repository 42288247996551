import React from "react"
import styled from 'styled-components';
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import NewsResumeBox  from '../components/newsResumeBox'
import FormatDate  from '../components/formatDate'
import { StructuredText } from "react-datocms"
import Seo from "../components/seo"

const Flex = styled.div`
    margin:0 auto;
    max-width:1150px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction:row;
    padding-bottom: 10rem;
    gap:5rem;
    @media (max-width: ${props => props.theme.breakpoints[0]}) {
        flex-direction:column-reverse;
        gap:2rem;
        } 
`

const IllustrationNews = styled.div`   
    margin-top:-5rem;
    width:100%;
    @media (min-width: ${props => props.theme.breakpoints[0]}) {
    max-width:500px;
} 
`

const ContentNews = styled.div`   
    width:100%;
    @media (min-width: ${props => props.theme.breakpoints[0]}) {
    max-width:727px;
} 
`



const News = ({data}) => {
 
  return (
  <Layout>
     <Seo meta={data.news.seoMetaTags} />
      <div className="container">
          <Flex> 
            
            <ContentNews>
                <span className="date-small gray"><FormatDate date={data.news.date}/></span>
                <h1>{data.news.title}</h1>
                <h2>{data.news.sousTitre}</h2>
                <StructuredText
                  data={data.news.content}
                  renderBlock={({ record }) => {
                    if (record.__typename === "DatoCmsImageBlock") {
                      return <GatsbyImage image={record.image.gatsbyImageData}
                      alt={``} />;
                    }

                    return (
                      <>
                        <p>Don't know how to render a block!</p>
                        <pre>{JSON.stringify(record, null, 2)}</pre>
                      </>
                    );
                  }}
                />
                
            </ContentNews> 
            <IllustrationNews>
                <GatsbyImage alt={data.news.title} image={data.news.coverImage.gatsbyImageData} />
            </IllustrationNews> 
          </Flex>
      </div>


    <section className="full-green">
      <div className="container">
        { data.allnews.edges.map(( itemNews,i) => ( 
          <NewsResumeBox key={i} {...itemNews.node} />
        ))
        }
      </div>
    </section>  
  </Layout>
  )
}

export default News


export const newsQuery = graphql`
   query($slug: String!){
    news:datoCmsNews( slug: { eq: $slug }) {
          id
          title
          sousTitre
          teaser
          date
          slug
          coverImage {
            gatsbyImageData (
              placeholder: BLURRED
            )
            alt
          }
          seoMetaTags  {
            ...GatsbyDatoCmsSeoMetaTags
          }
          content {
            value
            blocks {
              __typename
              ... on DatoCmsImageBlock {
                id: originalId
                image {
                  gatsbyImageData
                }
              }
            }
          }
    }

    allnews:allDatoCmsNews(sort: {order: DESC, fields: date},
      filter: { slug: { ne: $slug } }) {
      edges {
        node {
          id
          title
          sousTitre
          teaser
          date
          slug
          coverImage {
            gatsbyImageData (
              placeholder: BLURRED
            )
            alt
          }
        }
      }
    }
  }
`;